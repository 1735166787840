import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo/logo1.webp';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';



const Navbar = () => {

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
    var currentScrollPos = window.pageYOffset; 
    if (currentScrollPos > 50) {
      document.getElementById("goldPrice").style.top = "-28px";
      document.getElementById("mainNav").style.top = "0px";
    } 
    else if(currentScrollPos < 50){
      document.getElementById("goldPrice").style.top = "0px";
      document.getElementById("mainNav").style.top = "28px";
    }
    prevScrollpos = currentScrollPos;

  }


  let prezzo = "";
  let variazione = "";
  const [fixing, setFixing] = useState("");
  const [bullBear, setBullBear] = useState("");
  
  useEffect(() => {
    axios.post("https://newgest.careholding.it/principale.asmx/getprezzoORO", {}, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      [prezzo, variazione] = response.data.d.split('#'); 
      const fixingValue = prezzo;
      const bullBearValue = variazione;
      setBullBear(bullBearValue);
      setFixing(fixingValue);
    })
    .catch(error => {
      console.error("An error occurred:", error);
    });
  
  }, []);


  const handleNavLinkClick = () => {
    setTimeout(() => {
      const navbarCollapse = document.querySelector("#navbarResponsive");
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }

      window.scrollTo(0, 0);
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.setAttribute("aria-expanded", "false");
    }, 100);
  };

  const handleBodyClick = (event) => {
    const navbarCollapse = document.querySelector("#navbarResponsive");
    if (
      navbarCollapse.classList.contains("show") &&
      !event.target.closest("#navbarResponsive") &&
      !event.target.closest(".navbar-toggler")
    ) {
      navbarCollapse.classList.remove("show");
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.setAttribute("aria-expanded", "false");
    }
  };

  document.body.addEventListener("click", handleBodyClick);

  const handleNavbarTogglerClick = () => {
    const navbarCollapse = document.querySelector("#navbarResponsive");
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
      navbarToggler.setAttribute("aria-expanded", "false");
    } else {
      navbarCollapse.classList.add("show");
      navbarToggler.setAttribute("aria-expanded", "true");
    }
  };
  
  return (
    <div className="">
       <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-light shadow rounded-bottom" id="mainNav">
        <div className="d-block d-lg-none bg-light fixed-top mb-5 pb-2" id="goldPrice">
          <div className="container-fluid d-flex justify-content-center align-items-center text-light bg-fixing-price-lg">
              <p className="m-0 text-dark fw-bold">FIXING</p>&nbsp;&nbsp;
              <p className="m-0 text-dark fw-semibold">&euro;&nbsp;{fixing}</p>&nbsp;&nbsp;&nbsp;
              <span>                
              {bullBear >= 0 ? 
                  <>
                      <FontAwesomeIcon className="text-default text-success" icon={faCircleArrowUp}/>
                  </>
                  : 
                  <>
                      <FontAwesomeIcon className="text-default text-danger" icon={faCircleArrowDown}/>
                  </>
                }
              </span>&nbsp;
              <p className={`m-0 fw-bold ${bullBear >= 0 ? 'text-success' : 'text-danger'}`}>{bullBear}&nbsp;%</p>
          </div>
        </div>
            <div className="container" id="navbarGP">
                <Link className="navbar-brand" to="/" onClick={handleNavLinkClick}>
                  <img className="img-fluid l-size" src={Logo} alt="logo" />
                </Link>
                <button
                  className="navbar-toggler icon_menu"
                  type="button"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handleNavbarTogglerClick}
                >
                  <span></span>
                </button>

                <div className="collapse navbar-collapse py-4" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0 d-flex align-items-center mx-auto">
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/">Home</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/azienda">Azienda</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/notizie">Notizie</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/prodotti-e-servizi">Prodotti</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/collabora-con-noi">Collabora con noi</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/faq">Faq</Link></li>
                        <li className="nav-item"><Link className="nav-link text-dark text-opacity-75" onClick={handleNavLinkClick} to="/contatti">Contatti</Link></li>
                    </ul>
                    <Link to={`/area-clienti-collaboratori/`} onClick={handleNavLinkClick} className="nav-link text-center">
                      <button className="btn-default rounded px-3 py-2">
                        Area Riservata
                      </button>
                    </Link>
                </div>
            </div>
        </nav>
        <div className="custom-margin-top"/>

    </div>
  )
}

export default Navbar